import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { AccountContext } from "../Auth/AccountController";
import { AddRecipe } from "./AddRecipe";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import RecipeCardContainer from "./RecipeCardContainer";

export const RecipesPage = () => {
  const [show, setShow] = useState(false);
  const [dynamoClient, setDynamoClient] = useState<DynamoDBClient>();

  const context = useContext(AccountContext);

  const handleShow = () => setShow(!show);

  useEffect(() => {
    context?.getDynamoDbClient().then((res) => {
      setDynamoClient(res!);
    });
  }, [context]);

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Add New Recipe
      </Button>
      <AddRecipe show={show} onHide={handleShow} dynamoClient={dynamoClient} />
      <RecipeCardContainer dynamoClient={dynamoClient} />
    </div>
  );
};

export default RecipesPage;
