import React, { FormEvent, useRef, useState } from "react";
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Spinner from "react-bootstrap/Spinner"
import {CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js'
import UserPool from "../../../UserPool";
import {useNavigate} from "react-router-dom"

export const SignUp = () => {
    const userName = useRef<HTMLInputElement>(null)
    const password = useRef<HTMLInputElement>(null)
    const email = useRef<HTMLInputElement>(null)
    const token = useRef<HTMLInputElement>(null)

    const [username, setUserName] = useState('')
    const [isLoading, setLoading] = useState(false)
 
    const navigate = useNavigate()

    const [verify, setVerify] = useState(false)

    const signUpUser = (event: FormEvent) => {
        event.preventDefault();
        setLoading(true)
        setUserName(userName?.current!.value)

        const attributeList = []
        attributeList.push(new CognitoUserAttribute({
            Name: "email",
            Value: email?.current!.value
        }))


        UserPool.signUp(userName?.current!.value, password?.current!.value, attributeList, [], (err, data) => {
            if(data) {
                setVerify(true)
            }
        })
    }

    const verifyUser = (event: FormEvent) => {
        event.preventDefault()
        const user = new CognitoUser({
            Username: username,
            Pool: UserPool
        })
        user.confirmRegistration(token?.current!.value, true, (err, data) => {
            if(data) {
                navigate("/home", {replace: true})
            }
        })
    }

    return (
        <div>
            {verify === false ? 
                (
                    <Form onSubmit={signUpUser} className="custom-form">
                        <Form.Group className="mb-3" controlId="formBasicUserName">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Enter Username" as="input" ref={userName}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" as="input" ref={email}/>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter Password" as="input" ref={password}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {isLoading ? 
                                <Spinner as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true" /> : 'Sign Up'}
                        </Button>
                    </Form>
                ) : (
                    <Form onSubmit={verifyUser}>
                        <Form.Group className="mb-3" controlId="formBasicVerify">
                            <Form.Label>Enter Verification Token</Form.Label>
                            <Form.Control type="text" placeholder="Enter Token" as="input" ref={token}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {isLoading ? 
                                <Spinner as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true" /> : 'Login'}
                        </Button>
                    </Form>
                )
            }                  
        </div>
    )
}

export default SignUp