
import { NavDropdown } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from 'react'
import { AccountContext } from '../Auth/AccountController'
import { CognitoUser } from 'amazon-cognito-identity-js'
import './NavBar.css'

interface NavBarProps {
    status: boolean
}

export const NavBar = ({status}: NavBarProps) => {
    const [user, setUser] = useState<CognitoUser | null>(null)
    const  context = useContext(AccountContext)

    const navigate = useNavigate()

    useEffect(() => {
        setUser(context?.getUser()!)
    }, [context])

    return (
        <Navbar style={{backgroundColor: 'rgba(22 120 246 / 44%)'}} className="main-nav">
            <Container>
                <Navbar.Brand onClick={() => navigate("/home")} style={{cursor: 'pointer'}}>Nold's Magical Cloud</Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                    {status &&
                        <NavDropdown title={`Signed in as ${user?.getUsername()}`} id="nav-dropdown">
                            <NavDropdown.Item onClick={() => context?.logout()}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar