import { useQuery } from "@apollo/client";
import { GolfRoundsGQLResponse } from "../../Types/GraphQLResponses/GolfRoundsGQLResponse";
import { GOLF_ROUNDS } from "../../graphql/queries/golfRounds";
import { Spinner } from "react-bootstrap";
import { GolfRound } from "../../Types/GolfRound";
import { GolfRoundCard } from "./GolfRoundCard";

export const GolfRoundCardContainer = () => {
  const { data, loading, error } = useQuery<GolfRoundsGQLResponse>(GOLF_ROUNDS);

  return (
    <div className="custom-grid">
      {loading && <Spinner animation="border" variant="primary" />}
      {data &&
        data.golfRounds.rounds.map((round: GolfRound, index) => {
          return <GolfRoundCard key={index} round={round} />;
        })}
    </div>
  );
};
