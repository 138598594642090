import { IHomePageCard } from "../../../Types/HomePageCard";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import Tilt from "react-parallax-tilt";

export const HomePageCard = ({
  name,
  description,
  navigateTo,
  reRoute,
}: IHomePageCard) => {
  const navigate = useNavigate();

  const clickAction = () => {
    if (reRoute) {
      window.open(reRoute, "_blank");
    } else if (navigateTo) {
      navigate(navigateTo!);
    }
  };

  return (
    <Tilt>
      <Card
        data-tilt
        style={{ width: "19rem", height: "9rem" }}
        onClick={() => clickAction()}
        data-testid={"HomePageCardButton"}
      >
        <Card.Img variant="top" />
        <Card.Body>
          <Card.Title style={{ fontSize: "1.5rem" }}>{name}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </Card.Body>
      </Card>
    </Tilt>
  );
};
