import { gql } from "@apollo/client";

export const GOLF_ROUNDS = gql`
  query GolfRounds {
    golfRounds {
      rounds {
        course
        date
        score
        playedWith
        conditions
      }
      status
    }
  }
`;
