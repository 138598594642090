import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import { AccountContext } from "./Components/Auth/AccountController";
import { LoginPage } from "./Components/Login/LoginPage";
import NavBar from "./Components/NavBar/NavBar";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [status, setStatus] = useState(false);
  const context = useContext(AccountContext);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    context
      ?.getSession()
      .then((session) => {
        setStatus(true);
        if (location.pathname === "/") {
          navigate("/home", { replace: true });
        }
      })
      .catch((err: Error) => {
        console.log("Session: ", err);
        setStatus(false);
      });
  });

  return (
    <div className="App">
      <NavBar status={status} />
      {status ? <Outlet /> : <LoginPage />}
    </div>
  );
}

export default App;
