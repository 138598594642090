import { Button, Col, Form, Row } from "react-bootstrap";
import "./tripInfoContainer.css";
import { Expense } from "../../../Types/Expense";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { Trip } from "../../../Types/Trip";
import { AddExpense } from "./AddExpense";
import { useEffect, useRef, useState } from "react";
import { FaPencilAlt, FaSave } from "react-icons/fa";
import { updateTrip } from "../../../Utils/TripPlannerUtils";
import TripPhotoContainer from "./TripPhotoContainer";

interface ITripInfoContainerProps {
  dynamoClient: DynamoDBClient;
  trip: Trip;
}

export const TripInfoContainer = ({
  dynamoClient,
  trip,
}: ITripInfoContainerProps) => {
  const [show, setShow] = useState(false);
  const [editable, setEditable] = useState(false);
  const [tripDetails, setTripDetails] = useState("");
  const [tripPhoto, setTripPhoto] = useState("");
  const [totalPrice, setTotalPrice] = useState(0.0);

  const tripStart = useRef<HTMLInputElement>(null);
  const tripEnd = useRef<HTMLInputElement>(null);
  const tripHotel = useRef<HTMLInputElement>(null);
  const tripHotelLink = useRef<HTMLInputElement>(null);
  const tripTravel = useRef<HTMLInputElement>(null);
  const tripHotelPrice = useRef<HTMLInputElement>(null);
  const tripTravelPrice = useRef<HTMLInputElement>(null);
  const tripAmountSaved = useRef<HTMLInputElement>(null);

  const handleShow = () => setShow(!show);

  const handleEdit = async () => {
    if (editable) {
      trip.TripStartDate = tripStart?.current!.value;
      trip.TripEndDate = tripEnd?.current!.value;
      trip.TripHotel = tripHotel?.current!.value;
      trip.TripHotelLink = tripHotelLink?.current!.value;
      trip.TripTravel = tripTravel?.current!.value;
      trip.TripHotelPrice = tripHotelPrice?.current!.value;
      trip.TripTravelPrice = tripTravelPrice?.current!.value;
      trip.TripAmountSaved = tripAmountSaved?.current!.value;
      trip.TripDetails = tripDetails;
      if (tripPhoto !== "") {
        trip.TripPhoto = tripPhoto;
      }

      await updateTrip(trip, dynamoClient);
      window.location.reload();
      setEditable(!editable);
    } else {
      setEditable(!editable);
    }
  };

  const textAreaChange = (event: any) => {
    setTripDetails(event?.target.value);
  };

  //FINISH ME
  useEffect(() => {
    let calcTotalPrice = 0.0;
    calcTotalPrice += trip.TripHotelPrice
      ? parseFloat(trip.TripHotelPrice)
      : 0.0;
    calcTotalPrice += trip.TripTravelPrice
      ? parseFloat(trip.TripTravelPrice)
      : 0.0;

    trip.TripExpenses?.forEach((expense) => {
      calcTotalPrice += expense.expenseAmount
        ? parseFloat(expense.expenseAmount)
        : 0.0;
    });
    setTotalPrice(calcTotalPrice);
  }, [tripTravelPrice, tripHotelPrice, trip]);

  return (
    <>
      <Form>
        <div id="main-container">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
              marginBottom: "1%",
            }}
            onClick={handleEdit}
          >
            {editable ? <FaSave size={25} /> : <FaPencilAlt size={25} />}
          </div>
          <div id="columns">
            <div id="small-container">
              <div id="column1">
                <div>
                  <TripPhotoContainer
                    editable={editable}
                    fileName={trip.TripPhoto}
                    tripName={trip.TripName}
                    setTripPhoto={setTripPhoto}
                  />
                </div>
              </div>
              <div id="column2">
                <div id="column3">
                  <div id="name">{trip.TripName}</div>
                  <div id="date">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formTripDate"
                    >
                      <Form.Label column sm="2" className="topLabel">
                        Date
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          disabled={!editable}
                          type="date"
                          ref={tripStart}
                          defaultValue={trip.TripStartDate}
                        />
                      </Col>
                      <Col bsPrefix="customCol"> to </Col>
                      <Col sm="4">
                        <Form.Control
                          disabled={!editable}
                          type="date"
                          ref={tripEnd}
                          defaultValue={trip.TripEndDate}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div id="hotel">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formTripDate"
                    >
                      <Form.Label column sm="2" className="topLabel">
                        Hotel
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled={!editable}
                          type="text"
                          ref={tripHotel}
                          defaultValue={trip.TripHotel}
                        />
                      </Col>
                      <Form.Label column sm="2" className="topLabel">
                        Cost
                      </Form.Label>
                      <Col sm="2">
                        <Form.Control
                          disabled={!editable}
                          type="text"
                          ref={tripHotelPrice}
                          defaultValue={trip.TripHotelPrice}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div id="hotelLink">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formTripDate"
                    >
                      <Form.Label column sm="2" className="topLabel">
                        Hotel Link
                      </Form.Label>
                      <Col sm="10">
                        <Form.Control
                          disabled={!editable}
                          type="text"
                          ref={tripHotelLink}
                          defaultValue={trip.TripHotelLink}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div id="travel">
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formTripDate"
                    >
                      <Form.Label column sm="2" className="topLabel">
                        Travel
                      </Form.Label>
                      <Col sm="6">
                        <Form.Control
                          disabled={!editable}
                          type="text"
                          ref={tripTravel}
                          defaultValue={trip.TripTravel}
                        />
                      </Col>
                      <Form.Label column sm="2" className="topLabel">
                        Cost
                      </Form.Label>
                      <Col sm="2">
                        <Form.Control
                          disabled={!editable}
                          type="text"
                          ref={tripTravelPrice}
                          defaultValue={trip.TripTravelPrice}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="details">
            <Form.Group className="mb-3" controlId="formDetails">
              <Form.Label style={{ fontSize: "20px" }}>Details</Form.Label>
              <Form.Control
                disabled={!editable}
                as="textarea"
                rows={3}
                onChange={textAreaChange}
              />
            </Form.Group>
          </div>
          <div id="expenses" className="mt-3">
            <Button variant="primary" onClick={handleShow}>
              Add Expense +
            </Button>
            {trip && (
              <div id="expenseTable">
                {trip.TripExpenses &&
                  trip.TripExpenses!.map((expense: Expense, index) => {
                    return (
                      <div
                        key={index}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Row>
                          <Col xs="auto">{expense.expenseName}</Col>
                          <Col className="col-sm-auto">-------</Col>
                          <Col>${expense.expenseAmount}</Col>
                        </Row>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div id="price">Total Estimated Price: ${totalPrice.toFixed(2)}</div>
          <div id="saved">
            <Form.Group
              as={Row}
              className="mb-3 mt-4 justify-content-center "
              controlId="formTripDate"
            >
              <Form.Label column sm="auto" className="topLabel">
                Amount Saved
              </Form.Label>
              <Col sm="1">
                <Form.Control
                  disabled={!editable}
                  type="text"
                  ref={tripAmountSaved}
                  defaultValue={trip.TripAmountSaved}
                />
              </Col>
            </Form.Group>
          </div>
        </div>
      </Form>
      <AddExpense
        tripId={trip.TripID!}
        show={show}
        onHide={handleShow}
        dynamoClient={dynamoClient}
      />
    </>
  );
};

export default TripInfoContainer;
