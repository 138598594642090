export const homePageCards = [
  {
    name: "Daily Dead",
    description: "Grateful Dead concerts on this day in history",
    navigateTo: "/Daily",
    groups: ["Admin", "Daily"],
  },
  {
    name: "Recipe Database",
    description: "List of recipes from database",
    navigateTo: "/Recipes",
    groups: ["Admin", "Recipes"],
  },
  {
    name: "Hitman Level Selector",
    description: "Select a Hitman level from random",
    navigateTo: "/Hitman",
    groups: ["Admin", "Hitman"],
  },
  {
    name: "Budget",
    description: "Personal Budget Planner",
    navigateTo: "/Budget",
    groups: ["Admin", "SuperUser"],
  },
  {
    name: "Full Frontal Cloud",
    description: "Public S3 Bucket",
    navigateTo: "/FullFrontalCloud",
    groups: ["Admin"],
  },
  {
    name: "Katie's App",
    description: "Katie's Personal App",
    navigateTo: "/Katie",
    groups: ["Admin", "Katie"],
  },
  {
    name: "Golf Rounds",
    description: "Golf Rounds Information",
    navigateTo: "/Golf",
    groups: ["Admin"],
  },
  {
    name: "Add Dead CD",
    description: "Add a new Dead CD to dynamo collection",
    navigateTo: "/AddCD",
    groups: ["Admin"],
  },
  {
    name: "Trip Planner",
    description: "Personal Trip Planner",
    navigateTo: "/Planner",
    groups: ["Admin"],
  },
];
