import { useContext, useEffect, useState } from "react";
import { IHomePageCard } from "../../Types/HomePageCard";
import { filterHomePageCardsUtil } from "../../Utils/PermissionUtils";
import { AccountContext } from "../Auth/AccountController";
import { HomePageCard } from "./HomePageCard/HomePageCard";

export const HomePage = () => {
  const [cards, setCards] = useState<IHomePageCard[]>([]);

  const context = useContext(AccountContext);

  useEffect(() => {
    context?.getUserGroups().then((userGroups) => {
      if (userGroups) {
        setCards(filterHomePageCardsUtil(userGroups));
      }
    });
  }, []);

  return (
    <div className="custom-grid">
      {cards.map((item, index) => (
        <HomePageCard
          name={item.name}
          description={item.description}
          navigateTo={item.navigateTo}
          key={index}
          reRoute={item.reRoute}
        />
      ))}
    </div>
  );
};

export default HomePage;
