import { Button } from "react-bootstrap";
import { useState } from "react";
import { GolfRoundCardContainer } from "./GolfRoundCardContainer";

export const GolfRoundsPage = () => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(!show);

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        Add Round
      </Button>
      <GolfRoundCardContainer />
    </div>
  );
};

export default GolfRoundsPage;
