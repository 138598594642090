import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { createContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { DynamoDBClient, DynamoDBClientConfig } from "@aws-sdk/client-dynamodb";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import UserPool from "../../UserPool";
import { S3Client, S3ClientConfig } from "@aws-sdk/client-s3";

interface IAccountContext {
  getUser: () => CognitoUser | null;
  getSession: () => Promise<null | CognitoUserSession>;
  authenticate: (Username: string, Password: string) => Promise<void>;
  logout: () => void;
  getSessionIdToken: () => Promise<string | null>;
  getDynamoDbClient: () => Promise<DynamoDBClient | null>;
  getUserGroups: () => Promise<string[]>;
  getS3Client: () => Promise<S3Client | null>;
}

const AccountContext = createContext<IAccountContext | null>(null);

const AccountController = ({ children }: { children: ReactNode }) => {
  const COGNITO_ID = "cognito-idp.us-east-1.amazonaws.com/us-east-1_eJAJ58Tn9";

  const navigate = useNavigate();

  const getUser = (): CognitoUser | null => {
    const user = UserPool.getCurrentUser();
    return user;
  };

  const getUserGroups = async () => {
    return await new Promise<string[]>((resolve, reject) => {
      getSession().then((data) => {
        resolve(data?.getIdToken().payload!["cognito:groups"]);
      });
    });
  };

  const getSession = async (): Promise<null | CognitoUserSession> => {
    return await new Promise<null | CognitoUserSession>((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err: Error, session: null | CognitoUserSession) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });
  };

  const getSessionIdToken = async (): Promise<string | null> => {
    return await new Promise<string | null>((resolve, reject) => {
      getSession().then(
        (data) => {
          resolve(data?.getIdToken().getJwtToken()!);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  const authenticate = async (Username: string, Password: string) => {
    await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          console.log("login failure", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log("new password required", data);
          resolve(data);
        },
      });
    });
  };

  const getDynamoDbClient = async (): Promise<DynamoDBClient | null> => {
    return await new Promise<DynamoDBClient | null>((resolve, reject) => {
      try {
        getSessionIdToken().then((res) => {
          const config: DynamoDBClientConfig = {
            region: "us-east-1",
            credentials: fromCognitoIdentityPool({
              clientConfig: { region: "us-east-1" },
              identityPoolId: "us-east-1:3770714f-90d4-4437-a38c-d5a2b6f5576b",
              logins: {
                [COGNITO_ID]: res!,
              },
            }),
          };
          const dynamoClient = new DynamoDBClient(config);
          if (dynamoClient) {
            resolve(dynamoClient);
          }
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

  const getS3Client = async (): Promise<S3Client | null> => {
    return await new Promise<S3Client | null>((resolve, reject) => {
      try {
        getSessionIdToken().then((res) => {
          const config: S3ClientConfig = {
            region: "us-east-1",
            credentials: fromCognitoIdentityPool({
              clientConfig: { region: "us-east-1" },
              identityPoolId: "us-east-1:3770714f-90d4-4437-a38c-d5a2b6f5576b",
              logins: {
                [COGNITO_ID]: res!,
              },
            }),
          };
          const s3Client = new S3Client(config);

          if (s3Client) {
            resolve(s3Client);
          }
        });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });
  };

  const logout = () => {
    const user = UserPool.getCurrentUser();
    user?.signOut();
    navigate("/", { replace: true });
  };

  return (
    <AccountContext.Provider
      value={{
        getUser,
        authenticate,
        getSession,
        logout,
        getSessionIdToken,
        getDynamoDbClient,
        getUserGroups,
        getS3Client,
      }}
    >
      <>{children}</>
    </AccountContext.Provider>
  );
};

export { AccountController, AccountContext };
