import { useContext, useEffect, useState } from "react";
import EnterCd from "./EnterCd";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { AccountContext } from "../Auth/AccountController";
import { getCDs } from "../../Utils/CDUtils";
import { DeadCD } from "../../Types/DeadCD";
import { Spinner } from "react-bootstrap";
import { DeadCdWrapper } from "./DeadCdCardWrapper";

export const DeadCdTrackerPage = () => {
  const [dynamoClient, setDynamoClient] = useState<DynamoDBClient>();
  const [cds, setCds] = useState<DeadCD[] | null>([]);
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(AccountContext);

  useEffect(() => {
    setIsLoading(true);
    context?.getDynamoDbClient().then((res) => {
      setDynamoClient(res!);
      getCDs(res!).then((data) => {
        setIsLoading(false);
        setCds(data);
      });
    });
  }, [context]);

  return (
    <>
      <div className="add-cd-wrapper">
        <EnterCd />
      </div>
      {isLoading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        cds && <DeadCdWrapper cds={cds!} dynamoClient={dynamoClient!} />
      )}
    </>
  );
};

export default DeadCdTrackerPage;
