export const hitmanLevels = {
    levels: [
        {
            level: "Paris, France"
        },
        {
            level: "Sapienza, Italy"
        },
        {
            level: "Marrakesh, Morocco"
        },
        {
            level: "Bangkok, Thailand"
        },
        {
            level: "Colorado, USA"
        },
        {
            level: "Hokkaido, Japan"
        },
        {
            level: "Hawke's Bay, New Zealand"
        },
        {
            level: "Miami, USA"
        },
        {
            level: "Santa Fortuna, Colombia"
        },
        {
            level: "Mumbai, India"
        },
        {
            level: "Whittleton Creek, USA"
        },
        {
            level: "Isle of Sgail, Atlantic Ocean"
        },
        {
            level: "New York, USA"
        },
        {
            level: "Dubai, UAE"
        },
        {
            level: "Dartmoor, England"
        },
        {
            level: "Berlin, Germany"
        },
        {
            level: "Chongqing, China"
        },
        {
            level: "Mendoza, Argentina"
        },
        {
            level: "Ambrose Island, Andaman Sea"
        },
    ]
}