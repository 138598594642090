import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { useEffect, useState } from "react";
import { Trip } from "../../Types/Trip";
import { getTrips } from "../../Utils/TripPlannerUtils";
import { Spinner } from "react-bootstrap";
import TripPlannerCard from "./TripPlannerCard";

interface ITripPlannerContainer {
  dynamoClient: DynamoDBClient | undefined;
}

export const TripPlannerContainer = ({
  dynamoClient,
}: ITripPlannerContainer) => {
  const [trips, setTrips] = useState<Trip[] | null>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getTrips(dynamoClient).then((data) => {
      setTrips(data);
      setIsLoading(false);
    });
  }, [dynamoClient]);

  return (
    <div>
      <div className="custom-grid">
        {isLoading && <Spinner animation="border" variant="primary" />}
        {!isLoading &&
          trips?.map((trip: Trip, index) => {
            return (
              <TripPlannerCard
                key={index}
                dynamoClient={dynamoClient}
                trip={trip}
              />
            );
          })}
      </div>
    </div>
  );
};

export default TripPlannerContainer;
