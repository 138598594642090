import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../Auth/AccountController";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import TripPlannerContainer from "./TripPlannerContainer";
import { Button } from "react-bootstrap";
import { AddTrip } from "./AddTrip";

export const TripPlannerPage = () => {
  const [show, setShow] = useState(false);
  const [dynamoClient, setDynamoClient] = useState<DynamoDBClient>();

  const handleShow = () => setShow(!show);

  const context = useContext(AccountContext);

  useEffect(() => {
    context?.getDynamoDbClient().then((res) => {
      setDynamoClient(res!);
    });
  }, [context]);

  return (
    <div>
      <Button
        style={{ marginTop: "1%" }}
        variant="primary"
        onClick={handleShow}
      >
        Add New Trip
      </Button>
      <AddTrip show={show} onHide={handleShow} dynamoClient={dynamoClient} />
      <TripPlannerContainer dynamoClient={dynamoClient} />
    </div>
  );
};

export default TripPlannerPage;
