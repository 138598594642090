export const hexToRGB = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const calculateFontColor = (hex: string) => {
  const converted = hexToRGB(hex);
  if (
    converted &&
    converted?.r * 0.299 + converted?.g * 0.587 + converted?.b * 0.114 > 149
  ) {
    return "#000000";
  } else {
    return "#ffffff";
  }
};
