import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Recipe } from "../../Types/Recipe";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { deleteRecipe } from "../../Utils/RecipeUtils";

interface IRecipeCardProps {
  recipe: Recipe;
  dynamoClient: DynamoDBClient | undefined;
}

export const RecipeCard = ({ recipe, dynamoClient }: IRecipeCardProps) => {
  const toRecipe = () => {
    window.open(recipe.RecipeLink, "_blank");
  };

  const deleteRec = async () => {
    await deleteRecipe(dynamoClient, recipe.RecipeId);
  };

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body>
        <Card.Title>{recipe.RecipeName}</Card.Title>
        <Card.Text>{recipe.RecipeDescription}</Card.Text>
        <Button variant="primary" onClick={() => toRecipe()}>
          View Recipe
        </Button>{" "}
        <Button variant="danger" onClick={() => deleteRec()}>
          Delete
        </Button>{" "}
        <Button variant="warning">Edit</Button>{" "}
      </Card.Body>
    </Card>
  );
};

export default RecipeCard;
