import { useEffect, useState } from "react";
import { KatiesEntry } from "../../../Types/KatiesEntry";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { getKatiesEntries } from "../../../Utils/KateUtils";
import { Spinner } from "react-bootstrap";

interface IKatieEntriesList {
  dynamoClient: DynamoDBClient | undefined;
}

export const KatiesEntriesList = ({ dynamoClient }: IKatieEntriesList) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [entries, setEntries] = useState<KatiesEntry[] | null>([]);

  useEffect(() => {
    setIsLoading(true);
    getKatiesEntries(dynamoClient).then((data) => {
      data?.sort((a, b) => {
        return (
          new Date(b.EntryDate).valueOf() - new Date(a.EntryDate).valueOf()
        );
      });
      setEntries(data);
      setIsLoading(false);
    });
  }, [dynamoClient]);

  return (
    <div>
      <div style={{ fontSize: "30px", marginBottom: "10%" }}>
        Previous Dates
      </div>
      {isLoading && <Spinner animation="border" variant="primary" />}
      {entries?.map((item, index) => {
        return (
          <div style={{ fontSize: "20px" }} key={index}>
            {item.EntryDate}
          </div>
        );
      })}
    </div>
  );
};
