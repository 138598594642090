import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../Auth/AccountController";
import {
  GetObjectCommand,
  GetObjectCommandInput,
  GetObjectCommandOutput,
  PutObjectCommand,
  PutObjectCommandInput,
  S3Client,
} from "@aws-sdk/client-s3";
import { Form, Image } from "react-bootstrap";

interface ITripPhotoContainerProps {
  fileName: string | undefined;
  tripName: string | undefined;
  editable: boolean;
  setTripPhoto: (arg0: any) => void;
}

export const TripPhotoContainer = ({
  fileName,
  tripName,
  editable,
  setTripPhoto,
}: ITripPhotoContainerProps) => {
  const [s3Client, setS3Client] = useState<S3Client>();
  const [imageSrc, setImageSrc] = useState<
    string | ArrayBuffer | null | undefined
  >(null);

  const context = useContext(AccountContext);

  useEffect(() => {
    context?.getS3Client().then((res) => {
      setS3Client(res!);
      getFile(res!);
    });
  }, [context]);

  const getFile = async (s3Client: S3Client, newlyUploaded?: string) => {
    if (fileName || newlyUploaded) {
      const params: GetObjectCommandInput = {
        Bucket: "nmc-trip-planner-photos",
        Key: fileName,
      };

      try {
        const command = new GetObjectCommand(params);
        const response: GetObjectCommandOutput = await s3Client?.send(command);

        response.Body?.transformToByteArray().then((reader) => {
          if (reader) {
            const imageBlob = new Blob([reader]);
            const imageUrl = URL.createObjectURL(imageBlob);
            setImageSrc(imageUrl);
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const uploadFile = async (event: any) => {
    const fileExtension = event?.target.files[0].name.split(".").pop();

    const reader = new FileReader();

    reader.onload = (e) => {
      setImageSrc(e.target?.result);
    };

    reader.readAsDataURL(event?.target.files[0]);

    const uploadName = fileName
      ? `${fileName}`
      : `${tripName}.${fileExtension}`;
    const params: PutObjectCommandInput = {
      Bucket: "nmc-trip-planner-photos",
      Key: uploadName,
      Body: event?.target.files[0],
    };

    try {
      const command = new PutObjectCommand(params);
      const response = await s3Client?.send(command);
      if (response?.$metadata.httpStatusCode === 200) {
        setTripPhoto(uploadName);
        getFile(s3Client!, uploadName);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div id="photo">
        <div
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <Image
            src={imageSrc as string}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            fluid
          />
        </div>
      </div>
      {editable && (
        <Form.Group>
          <Form.Control type="file" onChange={uploadFile} />
        </Form.Group>
      )}
    </>
  );
};

export default TripPhotoContainer;
