import React, { useEffect, useState, useContext } from "react";
import { Concert } from "../../Types/Concert";
import ConcertCard from "./ConcertCard/ConcertCard";
import moment from "moment";
import HeaderBar from "./HeaderBar/HeaderBar";
import Spinner from "react-bootstrap/Spinner";
import { AccountContext } from "../Auth/AccountController";
import { filterConcerts } from "../../Utils/DailyDeadUtils";
import { formatConcertQueryDate } from "../../Utils/DateUtils";

export const DailyDeadPage = () => {
  const [filteredConcerts, setFilteredConcerts] = useState<Concert[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noResults, setNoResults] = useState(false);

  const context = useContext(AccountContext);

  // Fetch concerts from the api
  useEffect(() => {
    context?.getSessionIdToken()!.then((idToken) => {
      var now = moment();
      var month: string = (now.month() + 1).toString();
      var day = now.date().toString();

      var formatted = formatConcertQueryDate(month, day);

      fetch(
        `https://ovn3l0chw7.execute-api.us-east-1.amazonaws.com/default/DailyDeadConcerts?month=${formatted.month}&day=${formatted.day}`,
        {
          method: "GET",
          headers: new Headers({
            Authorization: idToken!,
          }),
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setFilteredConcerts(filterConcerts(json));
          setIsLoading(false);
        })
        .catch((error) => {
          setNoResults(true);
          setIsLoading(false);
        });
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HeaderBar />
      <div className="custom-grid">
        {isLoading && <Spinner animation="border" variant="primary" />}
        {noResults && <div>There are no concerts for today.</div>}
        {filteredConcerts &&
          filteredConcerts.map((concert: Concert, index) => {
            return <ConcertCard key={index} {...concert} />;
          })}
      </div>
    </div>
  );
};
export default DailyDeadPage;
