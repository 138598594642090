import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { FormEvent, useContext, useEffect, useRef, useState } from "react";
import { AccountContext } from "../Auth/AccountController";
import {
  Button,
  FloatingLabel,
  Form,
  Toast,
  ToastContainer,
  ToastHeader,
} from "react-bootstrap";
import { addCD } from "../../Utils/CDUtils";
import { DeadCdColors } from "../../data/DeadCDColors";

export const EnterCd = () => {
  const cdDate = useRef<HTMLInputElement>(null);
  const cdName = useRef<HTMLInputElement>(null);
  const newColorLabel = useRef<HTMLInputElement>(null);

  const [cdColor, setCdColor] = useState<string | undefined>(undefined);
  const [newColorSwitch, setNewColorSwitch] = useState(false);
  const [newCdColor, setNewCdColor] = useState<string | undefined>(undefined);
  const [colorOptions, setColorOptions] = useState(DeadCdColors);

  const [dynamoClient, setDynamoClient] = useState<DynamoDBClient>();

  const [showToast, setShowToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const context = useContext(AccountContext);

  useEffect(() => {
    context?.getDynamoDbClient().then((res) => {
      setDynamoClient(res!);
    });
  }, [context]);

  const resetForm = () => {
    cdDate.current!.value = "";
    cdName.current!.value = "";
    newColorLabel.current!.value = "";
    setCdColor(undefined);
    setNewColorSwitch(false);
  };

  const flipColorSwitch = () => {
    setNewColorSwitch(!newColorSwitch);
  };

  const submitCd = async (event: FormEvent) => {
    event.preventDefault();
    let addCdColor;
    //FIX ISSUE WITH ARRAY OF COLORS BEING IMMUTABLE
    if (newColorSwitch) {
      DeadCdColors.push({
        name: newColorLabel.current!.value,
        value: newCdColor!,
      });
      setColorOptions(DeadCdColors);
      addCdColor = newCdColor;
    } else {
      addCdColor = cdColor;
    }
    await addCD(
      cdDate?.current!.value,
      cdName?.current!.value,
      cdDate?.current!.value.slice(5),
      addCdColor!,
      dynamoClient
    ).then(
      (result) => {
        resetForm();
        setShowToast(true);
      },
      (error) => {
        console.log(error);
        setShowErrorToast(true);
      }
    );
  };
  return (
    <>
      <Form onSubmit={submitCd}>
        <Form.Group className="mb-3" controlId="cdDate">
          <FloatingLabel label="CD Date">
            <Form.Control
              required
              type="text"
              placeholder="Date"
              ref={cdDate}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="cdName">
          <FloatingLabel label="CD Name or Box Set">
            <Form.Control
              required
              type="text"
              placeholder="Name or Box Set"
              ref={cdName}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group className="mb-3" controlId="cdColor">
          <Form.Control
            as="select"
            onChange={(e) => {
              setCdColor(e.target!.value);
            }}
          >
            {colorOptions!.map((color, index) => {
              return (
                <option
                  value={color.value}
                  disabled={color.disabled}
                  key={index}
                >
                  {color.name}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group
          className="mb-3"
          controlId="cdNewColor"
          style={{ width: "15%", margin: "auto" }}
        >
          <Form.Switch label="New Color" onChange={() => flipColorSwitch()} />
        </Form.Group>
        {newColorSwitch && (
          <>
            <Form.Group className="mb-3" controlId="newCdColorName">
              <FloatingLabel label="Color Label">
                <Form.Control
                  required={newColorSwitch}
                  type="text"
                  placeholder="New Color Label"
                  ref={newColorLabel}
                />
              </FloatingLabel>
            </Form.Group>
            <Form.Group
              className="mb-3"
              style={{ margin: "auto", width: "15%" }}
            >
              <Form.Label htmlFor="colorPicker">Select New Color</Form.Label>
              <Form.Control
                required={newColorSwitch}
                style={{ margin: "auto" }}
                type="color"
                id="exampleColorInput"
                defaultValue="#563d7c"
                title="Choose your color"
                onChange={(value) => {
                  setNewCdColor(value.target.value);
                }}
              />
            </Form.Group>
          </>
        )}
        <Button
          variant="primary"
          type="submit"
          disabled={!cdColor && !newCdColor}
        >
          Add CD
        </Button>
      </Form>
      <ToastContainer
        className="p-3"
        position="bottom-center"
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          bg={"success"}
          show={showToast}
          delay={1500}
          autohide
        >
          <ToastHeader>
            <strong>Success</strong>
          </ToastHeader>
          <Toast.Body className={"text-white"}>
            Successfully submitted new CD
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ToastContainer
        className="p-3"
        position={"bottom-center"}
        style={{ zIndex: 1 }}
      >
        <Toast
          onClose={() => setShowErrorToast(false)}
          bg={"danger"}
          show={showErrorToast}
          delay={1500}
          autohide
        >
          <ToastHeader>
            <strong>Failure</strong>
          </ToastHeader>
          <Toast.Body className={"text-white"}>
            Failure to submit new CD
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default EnterCd;
