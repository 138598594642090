import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { FormEvent, useRef } from "react";
import { Button, FloatingLabel, Form, Offcanvas } from "react-bootstrap";
import { addTrip } from "../../Utils/TripPlannerUtils";

interface IAddTrip {
  show: boolean;
  onHide: () => void;
  dynamoClient: DynamoDBClient | undefined;
}

export const AddTrip = ({ show, onHide, dynamoClient }: IAddTrip) => {
  const tripName = useRef<HTMLInputElement>(null);

  const submitTrip = async (event: FormEvent) => {
    event.preventDefault();
    await addTrip(tripName?.current!.value, dynamoClient);
  };

  return (
    <Offcanvas show={show} onHide={onHide} backdrop="static" placement="top">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title style={{ marginLeft: "auto", marginRight: "auto" }}>
          Add Trip
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form
          style={{ marginLeft: "auto", marginRight: "auto", width: "25%" }}
          onSubmit={submitTrip}
        >
          <Form.Group className="mb-3">
            <FloatingLabel label="Trip Name">
              <Form.Control
                required
                type="text"
                placeholder="trip name"
                ref={tripName}
              />
            </FloatingLabel>
          </Form.Group>
          <Button variant="primary" type="submit">
            Add Trip
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
