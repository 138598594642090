import { Card } from "react-bootstrap";
import { Trip } from "../../Types/Trip";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { useNavigate } from "react-router-dom";

interface ITripPlannerCardProps {
  trip: Trip;
  dynamoClient: DynamoDBClient | undefined;
}

export const TripPlannerCard = ({
  trip,
  dynamoClient,
}: ITripPlannerCardProps) => {
  const navigate = useNavigate();

  return (
    <Card
      style={{ width: "18rem" }}
      onClick={() => navigate(`/Planner/${trip.TripID}`)}
    >
      <Card.Img variant="top" />
      <Card.Body>
        <Card.Title>{trip.TripName}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default TripPlannerCard;
