import React, { useState } from "react";
import Button  from "react-bootstrap/Button";
import LoginComponent from "./LoginComponent";
import SignUp from "./SignUp/SignUp";


export const LoginPage = () => {
    const [signingUp, setSigningUp] = useState(false)

    const loginText = "Already a member? Login."
    const signUpText = "Not a member? Sign Up."


    return (
        <div>
            {signingUp === false ? 
                (
                    <LoginComponent />
                ) : (
                    <SignUp />
                )
            }
            {/* <Button variant="link" onClick={() => {setSigningUp(!signingUp)}}> {signingUp ? loginText : signUpText} </Button> */}
        </div>
    )
}