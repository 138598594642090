export const DeadCdColors = [
  {
    name: "Select a color",
    value: undefined,
    disabled: true,
  },
  {
    name: "Daves Picks",
    value: "#f3722c",
  },
  {
    name: "Here Comes Sunshine",
    value: "#2a0599",
  },
  {
    name: "In and Out of The Garden",
    value: "#02c278",
  },
  {
    name: "July 78",
    value: "#a36d02",
  },
  {
    name: "June 76",
    value: "#bf042c",
  },
  {
    name: "Listen to the River",
    value: "#9b7dff",
  },
  {
    name: "May 77",
    value: "#80f736",
  },
  {
    name: "Pacific NW",
    value: "#570b01",
  },
  {
    name: "Saint of Circumstance: Giants Stadium",
    value: "#faecbb",
  },
];
