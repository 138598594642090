import { Button, Card } from "react-bootstrap";
import { GolfRound } from "../../Types/GolfRound";
import { useState } from "react";

interface IGolfCardProps {
  round: GolfRound;
}

export const GolfRoundCard = ({ round }: IGolfCardProps) => {
  const [more, setShowMore] = useState(false);
  console.log(round);

  const showMore = () => {
    console.log("Showing more");
    setShowMore(!more);
  };

  const renderScore = (score: number) => {
    var scoreRating = "";
    switch (true) {
      case score > 100:
        scoreRating = "bad";
        break;
      case score <= 90:
        scoreRating = "good";
        break;
      case score <= 95 && score > 90:
        scoreRating = "decent";
        break;
    }
    return <div className={`golfScore ${scoreRating}`}>{score}</div>;
  };

  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body>
        {renderScore(round.score)}
        <Card.Title>{round.course}</Card.Title>
        {more && <Card.Subtitle>{round.date}</Card.Subtitle>}
        {more && (
          <Card.Text>
            {round.playedWith.map((name: string, index) => {
              return <div key={index}>{name}</div>;
            })}
          </Card.Text>
        )}
        <Button variant="primary" onClick={() => showMore()}>
          Show More
        </Button>
      </Card.Body>
    </Card>
  );
};
