import {
  DynamoDBClient,
  PutItemCommand,
  PutItemCommandInput,
  PutItemCommandOutput,
  ScanCommand,
  ScanCommandInput,
  UpdateItemCommand,
  UpdateItemCommandInput,
} from "@aws-sdk/client-dynamodb";
import { DeadCD } from "../Types/DeadCD";
import { unmarshall } from "@aws-sdk/util-dynamodb";

const TABLE_NAME = "Dead_CD_Collection";

export const addCD = async (
  cdDate: string,
  cdName: string,
  monthDay: string,
  cdColor: string,
  dynamoClient: DynamoDBClient | undefined
) => {
  return await new Promise<PutItemCommandOutput>((resolve, reject) => {
    const input: PutItemCommandInput = {
      TableName: TABLE_NAME,
      Item: {
        ID: { S: cdDate },
        BoxSet: { S: cdName },
        MonthDay: { S: monthDay },
        BackgroundColor: { S: cdColor },
      },
    };
    try {
      dynamoClient?.send(new PutItemCommand(input)).then((data) => {
        if (data.$metadata.httpStatusCode === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    } catch (err) {
      console.log("Error: ", err);
      reject(err);
    }
  });
};

export const getCDs = async (dynamoClient: DynamoDBClient | undefined) => {
  return await new Promise<DeadCD[] | null>((resolve, reject) => {
    const allCds: DeadCD[] = [];
    const input: ScanCommandInput = {
      TableName: TABLE_NAME,
      ProjectionExpression:
        "ID, MonthDay, BoxSet, Listened, BackgroundColor, FontColor",
    };

    try {
      dynamoClient?.send(new ScanCommand(input)).then((data) => {
        data?.Items?.forEach((item) => {
          allCds.push(unmarshall(item) as DeadCD);
        });
        resolve(allCds);
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const updateCdListened = async (
  dynamoClient: DynamoDBClient | undefined,
  cd: DeadCD
) => {
  return await new Promise<DeadCD | null>((resolve, reject) => {
    const input: UpdateItemCommandInput = {
      TableName: TABLE_NAME,
      Key: {
        ID: { S: cd.ID },
        MonthDay: { S: cd.MonthDay },
      },
      UpdateExpression: "set Listened = :listened",
      ExpressionAttributeValues: {
        ":listened": { BOOL: cd.Listened! },
      },
      ReturnValues: "ALL_NEW",
    };

    try {
      dynamoClient?.send(new UpdateItemCommand(input)).then((data) => {
        if (data.$metadata.httpStatusCode === 200) {
          resolve(unmarshall(data.Attributes!) as DeadCD);
        } else {
          reject(data);
        }
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
