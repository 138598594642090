import { useContext, useEffect, useState } from "react";
import "react-day-picker/dist/style.css";
import { DatePicker } from "./KatieDatePicker/KatieDatePicker";
import { AccountContext } from "../Auth/AccountController";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { KatiesEntriesList } from "./KatiesEntriesList/KatiesEntriesList";

export const KatiesPage = () => {
  const [dynamoClient, setDynamoClient] = useState<DynamoDBClient>();

  const context = useContext(AccountContext);

  useEffect(() => {
    context?.getDynamoDbClient().then((res) => {
      setDynamoClient(res!);
    });
  }, [context]);

  return (
    <div className="custom-grid">
      <KatiesEntriesList dynamoClient={dynamoClient} />
      <DatePicker dynamoClient={dynamoClient} />
    </div>
  );
};
