import { useState } from "react";
import Button from "react-bootstrap/Button";
import { hitmanLevels } from "../../data/HitmanLevels";
import { IHitmanLevel } from "../../Types/HitmanLevel";

export const HitmanPage = () => {
  const [level, setLevel] = useState<IHitmanLevel | null>(null);

  const levels: IHitmanLevel[] = hitmanLevels.levels;

  const selectLevel = () => {
    setLevel(levels[Math.floor(Math.random() * levels.length)]);
  };

  return (
    <div data-testid="HitmanPageId">
      <Button variant="primary" onClick={selectLevel} data-testid="level-btn">
        Select Level
      </Button>
      {level && <div data-testid="level-div">{level.level}</div>}
    </div>
  );
};

export default HitmanPage;
