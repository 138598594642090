import { Button } from "react-bootstrap";
import { DayPicker } from "react-day-picker";

import "react-day-picker/dist/style.css";
import { addKatieEntry } from "../../../Utils/KateUtils";
import { format } from "date-fns";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { useState } from "react";

interface IDatePicker {
  dynamoClient: DynamoDBClient | undefined;
}

export const DatePicker = ({ dynamoClient }: IDatePicker) => {
  const [selectedDate, setSelectedDate] = useState<Date>();

  const submitDate = () => {
    addKatieEntry(format(selectedDate!, "MM/dd/yyyy").toString(), dynamoClient);
  };

  return (
    <div>
      <DayPicker
        mode="single"
        selected={selectedDate}
        onSelect={setSelectedDate}
      />
      <Button onClick={submitDate}>Submit Date</Button>
    </div>
  );
};
