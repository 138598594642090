import { FormEvent, useRef, useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { addRecipe } from "../../Utils/RecipeUtils";

interface IAddRecipe {
  show: boolean;
  onHide: () => void;
  dynamoClient: DynamoDBClient | undefined;
}

export const AddRecipe = ({ show, onHide, dynamoClient }: IAddRecipe) => {
  const recipeName = useRef<HTMLInputElement>(null);
  const recipeLink = useRef<HTMLInputElement>(null);
  const recipeType = useRef<HTMLInputElement>(null);
  const [recipeDescription, setRecipeDescription] = useState<string>("");

  const submitRecipe = async (event: FormEvent) => {
    event.preventDefault();
    await addRecipe(
      {
        RecipeName: recipeName?.current!.value,
        RecipeId: "",
        RecipeLink: recipeLink?.current!.value,
        RecipeType: recipeType?.current!.value,
        RecipeDescription: recipeDescription,
      },
      dynamoClient
    );
  };

  const textareaChange = (event: any) => {
    setRecipeDescription(event?.target.value);
  };

  return (
    <Offcanvas show={show} onHide={onHide} backdrop="static">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add Recipe</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={submitRecipe}>
          <Form.Group className="mb-3" controlId="formRecipeName">
            <FloatingLabel label="Recipe Name">
              <Form.Control
                required
                type="text"
                placeholder="recipe name"
                ref={recipeName}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formRecipeLink">
            <FloatingLabel label="Recipe Link">
              <Form.Control
                required
                type="text"
                placeholder="recipe link"
                ref={recipeLink}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formRecipeType">
            <FloatingLabel label="Recipe Type">
              <Form.Control
                required
                type="text"
                placeholder="recipe link"
                ref={recipeType}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formRecipeDescription">
            <FloatingLabel label="Description">
              <Form.Control
                as="textarea"
                placeholder="description"
                style={{ height: "150px" }}
                onChange={textareaChange}
              />
            </FloatingLabel>
          </Form.Group>
          <Button variant="primary" type="submit">
            Add Recipe
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddRecipe;
