import {
  DynamoDBClient,
  PutItemCommand,
  PutItemCommandInput,
  PutItemCommandOutput,
  ScanCommand,
  ScanCommandInput,
} from "@aws-sdk/client-dynamodb";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import { v4 as uuidv4 } from "uuid";
import { KatiesEntry } from "../Types/KatiesEntry";

export const addKatieEntry = async (
  date: string,
  dynamoClient: DynamoDBClient | undefined
) => {
  return await new Promise<PutItemCommandOutput>((resolve, reject) => {
    const input: PutItemCommandInput = {
      TableName: "Katies_Table_NMC",
      Item: {
        EntryId: { S: uuidv4() },
        EntryDate: { S: date.toString() },
      },
    };

    try {
      dynamoClient?.send(new PutItemCommand(input)).then((data) => {
        if (data.$metadata.httpStatusCode === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }

    window.location.reload();
  });
};

export const getKatiesEntries = async (
  dynamoClient: DynamoDBClient | undefined
) => {
  return await new Promise<KatiesEntry[] | null>((resolve, reject) => {
    const allEntries: KatiesEntry[] = [];
    const input: ScanCommandInput = {
      TableName: "Katies_Table_NMC",
      ProjectionExpression: "EntryId, EntryDate",
    };
    try {
      dynamoClient?.send(new ScanCommand(input)).then((data) => {
        data?.Items?.forEach((item) => {
          allEntries.push(unmarshall(item) as KatiesEntry);
        });
        resolve(allEntries);
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
