import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DeadCD } from "../../Types/DeadCD";
import { Card, ToggleButton } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import { useEffect, useState } from "react";
import { updateCdListened } from "../../Utils/CDUtils";
import { calculateFontColor, hexToRGB } from "../../Utils/GeneralUtils";

interface IDeadCdCardProps {
  cd: DeadCD;
  dynamoClient: DynamoDBClient | undefined;
  updateCdPosition: (cd: DeadCD) => void;
}

export const DeadCdCard = ({
  cd,
  dynamoClient,
  updateCdPosition,
}: IDeadCdCardProps) => {
  const [listened, setListened] = useState<boolean | undefined>(cd.Listened);
  const fontColor = calculateFontColor(cd.BackgroundColor);
  //Check is to handle existing daves pick that are gray cause I'm lazy and don't wanna change them all in dynamo
  const backgroundCalculated = hexToRGB(
    cd.BackgroundColor !== "#c7ced6" ? cd.BackgroundColor : "#f3722c"
  );

  const updateListened = async () => {
    setListened(!listened);
    if (cd.Listened === undefined) {
      cd.Listened = false;
    }
    cd.Listened = !cd.Listened;

    await updateCdListened(dynamoClient, cd).then((result) => {
      updateCdPosition(result!);
    });
  };

  useEffect(() => {
    setListened(cd.Listened);
  }, [cd.Listened]);

  return (
    <Tilt>
      <Card
        data-tilt
        style={{
          width: "20rem",
          border: `2px solid #000000`,
          backgroundImage: `linear-gradient(to bottom right, rgba(${
            backgroundCalculated!.r
          }, ${backgroundCalculated!.g}, ${
            backgroundCalculated!.b
          }, 0.4), rgba(${backgroundCalculated!.r}, ${
            backgroundCalculated!.g
          }, ${backgroundCalculated!.b}, 0.9), rgba(${
            backgroundCalculated!.r
          }, ${backgroundCalculated!.g}, ${backgroundCalculated!.b}, 0.4))`,
          //backgroundColor: `${cd.BackgroundColor}`,
          color: `${fontColor}`,
          boxShadow: "0 6px 10px 0 rgba(0,0,0,0.7)",
        }}
      >
        <Card.Body>
          <Card.Title>{cd.ID}</Card.Title>
          <Card.Text>{cd.BoxSet}</Card.Text>
          <ToggleButton
            className="mb-2"
            type="checkbox"
            variant={fontColor === "#000000" ? "outline-dark" : "outline-light"}
            checked={listened}
            value="1"
            onClick={(e) => updateListened()}
          >
            {listened ? "Listened \u2713" : "Not Listened"}
          </ToggleButton>
        </Card.Body>
      </Card>
    </Tilt>
  );
};

export default DeadCdCard;
