import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { FormEvent, useRef } from "react";
import { Button, FloatingLabel, Form, Offcanvas } from "react-bootstrap";
import { Expense } from "../../../Types/Expense";
import { addExpense } from "../../../Utils/TripPlannerUtils";

interface IAddExpense {
  tripId: string;
  show: boolean;
  onHide: () => void;
  dynamoClient: DynamoDBClient | undefined;
}

export const AddExpense = ({
  tripId,
  show,
  onHide,
  dynamoClient,
}: IAddExpense) => {
  const expenseName = useRef<HTMLInputElement>(null);
  const expenseAmount = useRef<HTMLInputElement>(null);

  const submitExpense = async (event: FormEvent) => {
    event.preventDefault();
    const newExpense: Expense = {
      expenseAmount: expenseAmount?.current!.value,
      expenseName: expenseName?.current!.value,
      tripID: tripId,
    };

    await addExpense(newExpense, dynamoClient);
  };

  return (
    <Offcanvas show={show} onHide={onHide} backdrop="static">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Add Expense</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onSubmit={submitExpense}>
          <Form.Group className="mb-3" controlId="formExpenseName">
            <FloatingLabel label="Name">
              <Form.Control
                required
                type="text"
                placeholder="expense name"
                ref={expenseName}
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formExpenseAmount">
            <FloatingLabel label="Amount">
              <Form.Control
                required
                type="text"
                placeholder="expense amount"
                ref={expenseAmount}
              />
            </FloatingLabel>
          </Form.Group>
          <Button variant="primary" type="submit">
            Add Expense
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
