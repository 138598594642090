import React from "react";
import Tilt from "react-parallax-tilt";
import { Concert } from "../../../Types/Concert";
import "./ConcertCard.css";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

export const ConcertCard = (
  { date, description, identifier, title, venue, year }: Concert,
  key: string
) => {
  const toArchive = () => {
    window.open(`https://archive.org/details/${identifier}`, "_blank");
  };

  return (
    <Tilt>
      <Card
        style={{ width: "18rem" }}
        onClick={() => toArchive()}
        data-testid="ConcertCardId"
      >
        <Card.Body>
          <Card.Title>{year}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{venue}</Card.Subtitle>
          <Card.Text>{description}</Card.Text>
          <Button
            variant="primary"
            onClick={() => toArchive()}
            data-testid="archiveButton"
          >
            Archive
          </Button>
        </Card.Body>
      </Card>
    </Tilt>
  );
};

export default ConcertCard;
