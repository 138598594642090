import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DeadCD} from "../../Types/DeadCD";
import DeadCdCard from "./DeadCdCard";
import {useState} from "react";
import DeadCdControlBar from "./DeadCdControlBar";

interface IDeadCdWrapperProps {
  cds: DeadCD[];
  dynamoClient: DynamoDBClient | undefined;
}

export const DeadCdWrapper = ({cds, dynamoClient}: IDeadCdWrapperProps) => {
  const [filteredCds, setFilteredCds] = useState<DeadCD[]>(cds);
  const [allCds, setAllCds] = useState<DeadCD[]>(cds);
  const [displayingAll, setDisplayingAll] = useState<boolean>(true);

  const filterCds = (searchString: string) => {
    if (searchString !== "") {
      setDisplayingAll(false);
    }
    setFilteredCds(
        cds.filter(
            (cd) =>
                cd.ID.includes(searchString) ||
                cd.BoxSet.toLocaleLowerCase().includes(searchString)
        )
    );
  };

  const filterListened = (filterRule: boolean) => {
    setDisplayingAll(false);
    setFilteredCds(
        cds.filter((cd) => {
          if (cd.Listened !== undefined) {
            return cd.Listened === filterRule;
          } else {
            return !filterRule;
          }
        })
    );
  };

  const updateCdPosition = (cd: DeadCD) => {
    const cdIndex = cds.findIndex(
        (obj) => obj.ID === cd?.ID && obj.BoxSet === cd.BoxSet
    );
    cds[cdIndex].Listened = cd?.Listened;
    setAllCds(cds);
  };

  const resetFilter = () => {
    setDisplayingAll(true);
  };

  return (
      <>
        <DeadCdControlBar
            filterCds={filterCds}
            filterListenedCds={filterListened}
            resetFilter={resetFilter}
        />
        <div className="custom-grid" style={{marginTop: "1rem"}}>
          {displayingAll
              ? allCds.map((cd: DeadCD, index) => {
                return (
                    <DeadCdCard
                        key={index}
                        cd={cd}
                        dynamoClient={dynamoClient}
                        updateCdPosition={updateCdPosition}
                    />
                );
              })
              : filteredCds.map((cd: DeadCD, index) => {
                return (
                    <DeadCdCard
                        key={index}
                        cd={cd}
                        dynamoClient={dynamoClient}
                        updateCdPosition={updateCdPosition}
                    />
                );
              })}
        </div>
      </>
  );
};
