export const BudgetPage = () => {
  return (
    <div data-testid="BudgetPageWrapper">
      {/* <iframe width="80%" height="700px" src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRW2GDLH-7CjAFFRK7DPNHBh6kFweAdQW_WEfF-OfSIcBWNTlYJ01057GTQS7vqlL4dx-7XfxQMNn-D/pubhtml?gid=347741908&amp;single=true&amp;widget=true&amp;headers=false"></iframe>*/}
      <iframe
        width="80%"
        height="1000px"
        src="https://docs.google.com/spreadsheets/d/1bGpbVUe5mYfIsOMKuK7OgLpXosaDBjAREyswh-VwknU/edit?usp=sharing"
        title="Budget Page"
      ></iframe>
    </div>
  );
};

export default BudgetPage;
