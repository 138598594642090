import React, { useEffect, useState } from "react";
import moment from "moment";

import { formatConcertQueryDate } from "../../../Utils/DateUtils";

export const HeaderBar = () => {
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  useEffect(() => {
    var now = moment();
    var month: string = (now.month() + 1).toString();
    var day: string = now.date().toString();
    var formatted = formatConcertQueryDate(month, day);
    setMonth(formatted.month);
    setDay(formatted.day);
  }, []);

  return (
    <div data-testid="HeaderBarId">
      <h1>
        Grateful Dead Concerts on {month}/{day}
      </h1>
    </div>
  );
};

export default HeaderBar;
