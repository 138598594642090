import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {useEffect, useState} from "react";
import {FloatingLabel, Spinner} from "react-bootstrap";
import {Recipe} from "../../Types/Recipe";
import RecipeCard from "./RecipeCard";
import {getRecipes} from "../../Utils/RecipeUtils";
import Form from "react-bootstrap/Form";

interface IRecipeCardContainer {
  dynamoClient: DynamoDBClient | undefined;
}

export const RecipeCardContainer = ({dynamoClient}: IRecipeCardContainer) => {
  const [recipes, setRecipes] = useState<Recipe[] | null>([]);
  const [filteredRecipes, setFilteredRecipes] = useState<Recipe[] | null>([])
  const [displayingAll, setDisplayingAll] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getRecipes(dynamoClient).then((data) => {
      setRecipes(data);
      setFilteredRecipes(data)
      setIsLoading(false);
    });
  }, [dynamoClient]);

  const searchRecipes = (searchString: string) => {
    if (searchString !== "") {
      setDisplayingAll(false);
    }
    setFilteredRecipes(recipes!.filter((recipe: Recipe) => recipe.RecipeName.toLocaleLowerCase().includes(searchString)))
  }

  return (
      <div>
        <Form className="cd-control-bar mb-0">
          <FloatingLabel label={"Search"}>
            <Form.Control type={"text"} placeholder="Blank"
                          onChange={(e) => searchRecipes(e.target.value.toLocaleLowerCase())}/>
          </FloatingLabel>
        </Form>
        <div className="custom-grid" style={{marginTop: "0"}}>
          {isLoading && <Spinner animation="border" variant="primary"/>}
          {!isLoading && (displayingAll ?
              recipes!.map((recipe: Recipe, index) => {
                return (
                    <RecipeCard
                        key={index}
                        dynamoClient={dynamoClient}
                        recipe={recipe}
                    />
                );
              }) : filteredRecipes!.map((recipe: Recipe, index) => {
                return (
                    <RecipeCard
                        key={index}
                        dynamoClient={dynamoClient}
                        recipe={recipe}
                    />
                );
              }))
          }
        </div>
      </div>
  );
};

export default RecipeCardContainer;
