import { Concert } from "../Types/Concert";

// Filter the concerts for unique years
export const filterConcerts = (loadedConcerts: Concert[] | any[]) => {
  var finalConcerts: Concert[] = [];
  loadedConcerts.forEach((concert: Concert) => {
    if (
      !finalConcerts.find((existing) => {
        return existing.year === concert.year;
      })
    ) {
      finalConcerts.push(concert);
    }
  });

  return finalConcerts;
};
