import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AccountController } from "./Components/Auth/AccountController";
import HomePage from "./Components/HomePage/HomePage";
import DailyDeadPage from "./Components/DailyDeadPage/DailyDeadPage";
import { RecipesPage } from "./Components/RecipesPage/RecipesPage";
import HitmanPage from "./Components/HitmanPage/HitmanSelectorPage";
import BudgetPage from "./Components/BudgetPage/BudgetPage";
import ProtectedRoute from "./Components/Auth/ProtectedRoute";
import { KatiesPage } from "./Components/KatiesPage/KatiesPage";
import { BucketPage } from "./Components/BucketPage/BucketPage";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import GolfRoundsPage from "./Components/GolfRounds/GolfRoundsPage";
import DeadCdTrackerPage from "./Components/DeadCdTrackerPage/DeadCdTrackerPage";
import TripPlannerPage from "./Components/TripPlannerPage/TripPlannerPage";
import TripPage from "./Components/TripPlannerPage/TripPage/TripPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: "http://localhost:4000",
});

root.render(
  <BrowserRouter>
    <ApolloProvider client={apolloClient}>
      <AccountController>
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              path="home"
              element={
                <ProtectedRoute path={"Home"}>
                  <HomePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="daily"
              element={
                <ProtectedRoute path={"Daily"}>
                  <DailyDeadPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="recipes"
              element={
                <ProtectedRoute path={"Recipes"}>
                  <RecipesPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="hitman"
              element={
                <ProtectedRoute path={"Hitman"}>
                  <HitmanPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="budget"
              element={
                <ProtectedRoute path={"Budget"}>
                  <BudgetPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="Katie"
              element={
                <ProtectedRoute path={"Katie"}>
                  <KatiesPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="FullFrontalCloud"
              element={
                <ProtectedRoute path={"FullFrontalCloud"}>
                  <BucketPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="Golf"
              element={
                <ProtectedRoute path={"Golf"}>
                  <GolfRoundsPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="AddCD"
              element={
                <ProtectedRoute path={"AddCD"}>
                  <DeadCdTrackerPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="Planner"
              element={
                <ProtectedRoute path={"Planner"}>
                  <TripPlannerPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="Planner/:planId"
              element={
                <ProtectedRoute path={"Planner"}>
                  <TripPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </AccountController>
    </ApolloProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
