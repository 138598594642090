import {ChangeEvent, useState} from "react";
import {FloatingLabel, Form} from "react-bootstrap";

interface IDeadCdControlBarProps {
  filterCds: (filterString: string) => void;
  filterListenedCds: (filterRule: boolean) => void;
  resetFilter: () => void;
}

export const DeadCdControlBar = ({
                                   filterCds,
                                   filterListenedCds,
                                   resetFilter,
                                 }: IDeadCdControlBarProps) => {
  const [filterListened, setFilerListened] = useState<boolean>(false);
  const [filterNot, setFilterNot] = useState<boolean>(false);

  const switchListened = (e: ChangeEvent) => {
    setFilerListened(!filterListened);
    if (!filterListened) {
      filterListenedCds(true);
    } else {
      resetFilter();
    }
  };

  const switchNot = (e: ChangeEvent) => {
    setFilterNot(!filterNot);
    if (!filterNot) {
      filterListenedCds(false);
    } else {
      resetFilter();
    }
  };

  return (
      <div>
        <Form className=" cd-control-bar mb-0">
          <FloatingLabel label="Filter">
            <Form.Control
                type="text"
                placeholder="Date"
                onChange={(e) => filterCds(e.target.value.toLocaleLowerCase())}
            />
          </FloatingLabel>
          <Form.Check
              type="switch"
              id="custom-switch"
              label="Listened"
              className="dead-cd-switch"
              defaultChecked={filterListened}
              onChange={switchListened}
          />
          <Form.Check
              type="switch"
              id="custom-switch"
              label="Not Listened"
              defaultChecked={filterNot}
              className="dead-cd-switch"
              onChange={switchNot}
          />
        </Form>
      </div>
  );
};

export default DeadCdControlBar;
