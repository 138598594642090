import { Breadcrumb, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import TripInfoContainer from "./TripInfoContainer";
import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { Trip } from "../../../Types/Trip";
import { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../Auth/AccountController";
import { getTrip } from "../../../Utils/TripPlannerUtils";

export const TripPage = () => {
  const returnString = "<- Return to Trips";
  const [dynamoClient, setDynamoClient] = useState<DynamoDBClient>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [trip, setTrip] = useState<Trip | null>(null);
  const { planId } = useParams();

  const context = useContext(AccountContext);

  useEffect(() => {
    setIsLoading(true);
    context?.getDynamoDbClient().then((res) => {
      setDynamoClient(res!);
      getTrip(res!, planId!).then((data: any) => {
        setIsLoading(false);
        setTrip(data);
      });
    });
  }, [context]);

  const navigate = useNavigate();
  return (
    <>
      {isLoading && <Spinner animation="border" variant="primary" />}
      {!isLoading && (
        <div className="trip-page-container">
          <Breadcrumb>
            <Breadcrumb.Item onClick={() => navigate("/Planner")}>
              {returnString}
            </Breadcrumb.Item>
          </Breadcrumb>
          {trip && (
            <TripInfoContainer dynamoClient={dynamoClient!} trip={trip!} />
          )}
        </div>
      )}
    </>
  );
};

export default TripPage;
