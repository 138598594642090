import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "./AccountController";

interface ProtectedRouteProps {
  children?: JSX.Element;
  path: string;
}

export const ProtectedRoute = ({ children, path }: ProtectedRouteProps) => {
  const context = useContext(AccountContext);
  const navigate = useNavigate();

  useEffect(() => {
    context?.getUserGroups().then((userGroups) => {
      if (
        !userGroups ||
        !(userGroups.includes(path) || userGroups.includes("Admin"))
      ) {
        navigate("/home", { replace: true });
      }
    });
  }, []);

  return children!;
};

export default ProtectedRoute;
