import React, { FormEvent, useRef, useContext, useState} from "react";
import {useNavigate} from "react-router-dom"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { AccountContext } from "../Auth/AccountController";

import "./LoginPage.css"
import Spinner from "react-bootstrap/Spinner";

export const LoginComponent = () => {
    const userName = useRef<HTMLInputElement>(null)
    const password = useRef<HTMLInputElement>(null)

    const [isLoading, setLoading] = useState(false)

    const navigate = useNavigate()

    const context = useContext(AccountContext)

    const loginUser = (event: FormEvent) => {
        event.preventDefault()
        setLoading(true)
        context?.authenticate(userName?.current!.value, password?.current!.value)
            .then((data) => {
                navigate("/home", {replace: true})
            })
            .catch((err) => {
                console.log(err)
                alert('login failure')
            })
    }
    

    return (
        <Form onSubmit={loginUser} className="custom-form">
            <Form.Group className="mb-3" controlId="formBasicUserName">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" placeholder="Enter Username" ref={userName}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter Password" ref={password}/>
            </Form.Group>
            <Button variant="primary" type="submit">
                {isLoading ? 
                    <Spinner as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" /> : 'Login'}
            </Button>
        </Form>
    )
}

export default LoginComponent