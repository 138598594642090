export const formatConcertQueryDate = (month: string, day: string) => {
  if (Number(month) < 10) {
    month = "0" + month;
  }
  if (Number(day) < 10) {
    day = "0" + day;
  }

  return { month: month, day: day };
};
