import {
  DeleteItemCommandInput,
  DeleteItemCommand,
  DynamoDBClient,
  ScanCommand,
  ScanCommandInput,
  DeleteItemCommandOutput,
  PutItemCommandInput,
  PutItemCommand,
  PutItemCommandOutput,
} from "@aws-sdk/client-dynamodb";
import { v4 as uuidv4 } from "uuid";
import { Recipe } from "../Types/Recipe";
import { unmarshall } from "@aws-sdk/util-dynamodb";

export const getRecipes = async (dynamoClient: DynamoDBClient | undefined) => {
  return await new Promise<Recipe[] | null>((resolve, reject) => {
    const allRecipes: Recipe[] = [];
    const input: ScanCommandInput = {
      TableName: "Recipes_NMC",
      ProjectionExpression:
        "RecipeId, RecipeName, RecipeType, RecipeLink, RecipeDescription",
    };
    try {
      dynamoClient?.send(new ScanCommand(input)).then((data) => {
        data?.Items?.forEach((item) => {
          allRecipes.push(unmarshall(item) as Recipe);
        });
        resolve(allRecipes);
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const deleteRecipe = async (
  dynamoClient: DynamoDBClient | undefined,
  recipeId: string
) => {
  return await new Promise<DeleteItemCommandOutput>((resolve, reject) => {
    const input: DeleteItemCommandInput = {
      TableName: "Recipes_NMC",
      Key: {
        RecipeId: { S: recipeId },
      },
    };

    try {
      dynamoClient?.send(new DeleteItemCommand(input)).then((data) => {
        if (data.$metadata.httpStatusCode === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }

    window.location.reload();
  });
};

export const addRecipe = async (
  recipe: Recipe,
  dynamoClient: DynamoDBClient | undefined
) => {
  return await new Promise<PutItemCommandOutput>((resolve, reject) => {
    const input: PutItemCommandInput = {
      TableName: "Recipes_NMC",
      Item: {
        RecipeId: { S: uuidv4() },
        RecipeName: { S: recipe.RecipeName },
        RecipeLink: { S: recipe.RecipeLink },
        RecipeType: { S: recipe.RecipeType },
        RecipeDescription: { S: recipe.RecipeDescription },
      },
    };
    try {
      dynamoClient?.send(new PutItemCommand(input)).then((data) => {
        if (data.$metadata.httpStatusCode === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    } catch (err) {
      console.log("Error: ", err);
      reject(err);
    }

    window.location.reload();
  });
};
