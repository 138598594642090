import {
  DynamoDBClient,
  PutItemCommand,
  PutItemCommandInput,
  PutItemCommandOutput,
  QueryCommand,
  QueryInput,
  ScanCommand,
  ScanCommandInput,
  UpdateItemCommand,
  UpdateItemCommandInput,
  UpdateItemCommandOutput,
} from "@aws-sdk/client-dynamodb";
import { Trip } from "../Types/Trip";
import { v4 as uuidv4 } from "uuid";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import { Expense } from "../Types/Expense";

export const addTrip = async (
  tripName: string,
  dynamoClient: DynamoDBClient | undefined
) => {
  return await new Promise<PutItemCommandOutput>((resolve, reject) => {
    const input: PutItemCommandInput = {
      TableName: "Trip_Planner_NMC",
      Item: {
        TripID: { S: uuidv4() },
        TripName: { S: tripName },
      },
    };
    try {
      dynamoClient?.send(new PutItemCommand(input)).then((data) => {
        if (data.$metadata.httpStatusCode === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    } catch (err) {
      console.log("Error: ", err);
      reject(err);
    }

    window.location.reload();
  });
};

export const updateTrip = async (trip: Trip, dynamoClient: DynamoDBClient) => {
  return await new Promise<UpdateItemCommandOutput>((resolve, reject) => {
    const updateCommand: UpdateItemCommandInput = {
      TableName: "Trip_Planner_NMC",
      Key: {
        TripID: { S: `${trip.TripID}` },
      },
      UpdateExpression:
        "set TripHotel = :tripHotel, TripStartDate = :tripStartDate, TripEndDate = :tripEndDate, TripPhoto = :tripPhoto, TripHotelLink = :tripHotelLink, TripTravel = :tripTravel, TripDetails = :tripDetails,  TripHotelPrice = :tripHotelPrice, TripTravelPrice = :tripTravelPrice, TripAmountSaved = :tripAmountSaved",
      ExpressionAttributeValues: {
        ":tripHotel": { S: `${trip.TripHotel}` },
        ":tripStartDate": { S: `${trip.TripStartDate}` },
        ":tripEndDate": { S: `${trip.TripEndDate}` },
        ":tripPhoto": { S: `${trip.TripPhoto}` },
        ":tripHotelLink": { S: `${trip.TripHotelLink}` },
        ":tripTravel": { S: `${trip.TripTravel}` },
        ":tripDetails": { S: `${trip.TripDetails}` },
        ":tripHotelPrice": { S: `${trip.TripHotelPrice}` },
        ":tripTravelPrice": { S: `${trip.TripTravelPrice}` },
        ":tripAmountSaved": { S: `${trip.TripAmountSaved}` },
      },
      ReturnValues: "ALL_NEW",
    };

    try {
      dynamoClient?.send(new UpdateItemCommand(updateCommand)).then((data) => {
        if (data.$metadata.httpStatusCode === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    } catch (err) {
      console.log("Error: ", err);
      reject(err);
    }

    //window.location.reload();
  });
};

export const addExpense = async (
  expense: Expense,
  dynamoClient: DynamoDBClient | undefined
) => {
  return await new Promise<PutItemCommandOutput>((resolve, reject) => {
    const input: PutItemCommandInput = {
      TableName: "Trip_Expenses_NMC",
      Item: {
        expenseID: { S: uuidv4() },
        expenseAmount: { S: expense.expenseAmount! },
        expenseName: { S: expense.expenseName! },
        tripID: { S: expense.tripID! },
      },
    };

    try {
      console.log(input);
      dynamoClient?.send(new PutItemCommand(input)).then((data) => {
        if (data.$metadata.httpStatusCode === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    } catch (err) {
      console.log("Error: ", err);
      reject(err);
    }

    window.location.reload();
  });
};

export const getTrips = async (dynamoClient: DynamoDBClient | undefined) => {
  return await new Promise<Trip[] | null>((resolve, reject) => {
    const allRecipes: Trip[] = [];
    const input: ScanCommandInput = {
      TableName: "Trip_Planner_NMC",
      ProjectionExpression: "TripID, TripName, TripPhoto",
    };

    try {
      dynamoClient?.send(new ScanCommand(input)).then((data) => {
        data?.Items?.forEach((item) => {
          allRecipes.push(unmarshall(item) as Trip);
        });
        resolve(allRecipes);
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const getTrip = async (
  dynamoClient: DynamoDBClient | undefined,
  tripId: string
) => {
  return await new Promise<Trip | null>((resolve, reject) => {
    const allExpenses: Expense[] = [];
    const expenseInput: QueryInput = {
      ExpressionAttributeValues: { ":s": { S: `${tripId}` } },
      TableName: "Trip_Expenses_NMC",
      IndexName: "tripID-index",
      ProjectionExpression: "expenseID, expenseAmount, expenseName, tripID",
      KeyConditionExpression: "tripID = :s",
    };

    const tripInput: QueryInput = {
      ExpressionAttributeValues: { ":s": { S: `${tripId}` } },
      TableName: "Trip_Planner_NMC",
      ProjectionExpression:
        "TripID, TripName, TripStartDate, TripEndDate, TripTravel, TripPrice, TripHotel, TripHotelLink, TripPhoto, TripDetails, TripHotelPrice, TripTravelPrice, TripAmountSaved",
      KeyConditionExpression: "TripID = :s",
    };

    try {
      let trip: Trip | null = null;
      dynamoClient?.send(new QueryCommand(tripInput)).then((data: any) => {
        if (data.Items) {
          trip = unmarshall(data.Items[0]) as Trip;
          dynamoClient?.send(new QueryCommand(expenseInput)).then((data) => {
            data?.Items?.forEach((item) => {
              allExpenses.push(unmarshall(item) as Expense);
              if (trip) {
                trip!.TripExpenses = allExpenses;
              }
            });
            resolve(trip);
          });
        }
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};
